.widget {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 14px;
  background: #fff;
  margin: 1rem 0;
}

.widget.wide {
  width: 256px;
  height: 120px;

  display: grid;
  grid-template-columns: 50% 50%;

  .widget-left {
    padding-right: 1rem;
    border-right: 2px solid #F7F4F1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .widget-right {
    padding-left: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 3fr);
    gap: 0.25rem;
  }
}

.widget.tall {
  height: 392px;
  width: 256px;
}

.widget.large {
  width: 256px;
  height: 256px;

  .widget-large-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    small {
      color: #84827E;
    }

    h2 {
      color: #00A872;
      font-weight: 700;
    }
  }
}

.widget.green {
  background-color: #00A872;
  color: #fff;

  h1 {
    color: #fff;
  }

  .dashboard-widget-title-place {
    color: #BAE5CF;
  }

  .dashboard-widget-info {
    color: #fff;
  }
}

.widget.red {
  background-color: #932F2F;
  color: #fff;

  h1 {
    color: #fff;
  }

  .dashboard-widget-title-place {
    color: #F2BABA;
  }

  .dashboard-widget-info {
    color: #fff;
  }
}

.widget.blue {
  background-color: #EEF7FC;
}

.widget.yellow {
  background-color: #FFFBF1;

  h1, .dashboard-widget-footer {
    color: #F4950B;
  }

  .dashboard-widget-footer svg path {
    fill: #F4950B;
  }
}

.widget.wide.light {
  background-color: #FFFBF1;
  padding: 0;

  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-end;

  h1 {
    margin-bottom: 0;
  }

  small {
    color: #797672;
    font-weight: 700;
    font-size: 10px;
  }

  .widget-left, .widget-right {
    margin: 1rem 0;
  }

  .widget-left {
    padding: 0 1rem;
  }

  .widget-right {
    padding: 0 1rem;
    color: #F4950B;
    font-weight: 700;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  > div:nth-last-child(1) {
    grid-column: 1 / 2 span;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 9.77%, rgba(255, 255, 255, 0.9) 9.78%, rgba(255, 255, 255, 0) 89.65%), #504D49;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .dashboard-widget-title-place {
    margin-bottom: 0;
  }

  .dashboard-widget-footer {
    height: 36px;

    > div {
      width: 100%;
    }

    > div .ant-slider {
      margin: auto 24px;
    }

    > div .ant-slider-rail {
      background-color: transparent;
    }

    > div .ant-slider-track {
      background-color: transparent;
    }

    > div .ant-slider-handle {
      width: 20px;
      height: 20px;
      border-radius: 8px;
      margin-top: -7px;
      box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.16);
      background-repeat: no-repeat;
      background-position: center;
      border: none;
    }
  }
}

.widget.wide.light.saturation {
  > div:nth-last-child(1) {
    grid-column: 1 / 2 span;
    background: linear-gradient(270deg, #01EAF2 0.15%, #F7F4F1 92.72%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.widget.wide.light.color {
  > div:nth-last-child(1) {
    grid-column: 1 / 2 span;
    background: linear-gradient(90deg, #FF0000 0%, #FF6E00 11.79%, #FFE600 22.5%, #F6E600 23.04%, #20E800 35.9%, #00FFF0 49.29%, #0038FF 63.22%, #9E00FF 75.01%, #FF00BC 88.41%, #FF0000 102.87%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.widget.wide.light.temp {
  > div:nth-last-child(1) {
    grid-column: 1 / 2 span;
    background: linear-gradient(270deg, #AFE0F8 14.71%, #FFC52B 100%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.widget.large.light {
  padding: 0;

  .dashboard-widget-title {
    border-bottom: 2px solid #F7F4F1;
    padding: 1rem;
    background-color: #FFFBF1;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    h1, p {
      margin-bottom: 0;
    }

    h1 {
      color: #F4950B;
    }

    .widget-info {
      text-align: right;
      font-weight: 700;
      color: #F4950B;
    }

    .widget-info img {
      margin-left: 0.5rem;
    }
  }
}

.widget-large-footer.light-ww {
  padding: 1rem;

  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 1rem;

  small {
    color: #797672;
    font-weight: 700;
    font-size: 12px;
  }

  .ant-slider {
    margin: 0 24px;
  }

  .ant-slider-rail, .ant-slider-track {
    background-color: transparent;
  }

  .ant-slider-handle {
    background: #FFFFFF;
    width: 20px;
    height: 20px;
    box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    margin-top: 4px;
  }

  .light-slider.brightness, .light-slider.temperature {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 9.77%, rgba(255, 255, 255, 0.9) 9.78%, rgba(255, 255, 255, 0) 89.65%), #504D49;
    border-radius: 12px;
    height: 36px;
  }

  .light-slider.temperature {
    background: linear-gradient(270deg, #AFE0F8 14.71%, #FFC52B 100%);
  }
}

.widget.tall.light {
  padding: 0;
  background-color: #fff;

  &.yellow {
    .dashboard-widget-title {
      background-color: #FFFBF1;

      h1, .widget-info {
        color: #F4950B;
      }

      svg path {
        fill: #F4950B;
      }
    }
  }

  .dashboard-widget-title {
    border-bottom: 2px solid #F7F4F1;
    padding: 1rem;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    h1, p {
      margin-bottom: 0;
    }

    h1 {
      color: var(--gray);
    }

    .widget-info {
      font-weight: 700;
      color: var(--gray);

      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        margin-left: 0.5rem;
      }
    }
  }

}

.dashboard-widget-footer {
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #797672;

  p {
    margin-bottom: 0;
  }
}

.dashboard-widget-footer.light {
  display: grid;
  grid-template-columns: repeat(1, 3fr);
  gap: 8px;

  padding: 1rem;

  .mode {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;

    button {
      border: none;
      background: #F7F4F1;
      border-radius: 12px !important;
      font-size: 12px;
      color: var(--brown);
      font-weight: 700;
      width: 100%;

      &:nth-last-child(1) {
        color: #FFFFFF;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
      }

      img {
        margin-right: 0.5rem;
      }
    }
  }

  .color {
    display: grid;
    grid-template-columns: repeat(1, 3fr);
    gap: 8px;

    small {
      color: var(--gray);
    }

  }

  .white small {
    color: var(--gray);
  }

  .disabled {

    small {
      color: #EFE8E1;
    }

    .light-slider {
      background: #EFE8E1 !important;
    }

    .ant-slider-handle {
      display: none;
    }
  }

}

// Antd
// ---------------------------------------------------
.ant-slider {
  margin: auto 24px;
}

.ant-slider-rail {
  background-color: transparent;
}

.ant-slider-track {
  background-color: transparent;
}

.ant-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.16);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}

.light-slider.color {
  height: 36px;
  background: linear-gradient(90deg, #FF0000 0%, #FF6E00 11.79%, #FFE600 22.5%, #F6E600 23.04%, #20E800 35.9%, #00FFF0 49.29%, #0038FF 63.22%, #9E00FF 75.01%, #FF00BC 88.41%, #FF0000 102.87%);
  border-radius: 12px;

  .ant-slider-handle {
    margin-top: -7px;
  }
}

.light-slider.saturation {
  height: 36px;
  background: linear-gradient(270deg, #01EAF2 0.15%, #F7F4F1 92.72%);
  border-radius: 12px;
}

.light-slider.brightness {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 9.77%, rgba(255, 255, 255, 0.9) 9.78%, rgba(255, 255, 255, 0) 89.65%), #504D49;
  border-radius: 12px;
  height: 36px;
}

.light-slider.temperature {
  background: linear-gradient(270deg, #AFE0F8 14.71%, #FFC52B 100%);
  border-radius: 12px;
  height: 36px;
}

// SetTopBox
// ---------------------------------------------------
.settopbox-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;

  align-items: center;

  button {
    padding: 0;
    background: #F7F4F1;
    border-radius: 8px !important;
    border: none;
    font-size: 12px;
    color: #504D49;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  button:nth-child(1) {
    grid-row: 1 / span 2;
  }

  button:nth-last-child(1) {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
  }
}


// AirConditioner
// ---------------------------------------------------
.widget-large-footer.air-conditioner {
  display: grid;
  grid-template-columns: auto 20% auto;
  gap: 4px;
  width: 100%;

  button {
    padding: 0;
    background: #F7F4F1;
    border-radius: 8px !important;
    border: none;
    font-size: 12px;
    color: #504D49;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:nth-child(2) img, button:nth-child(3) img {
    width: 20px;
    height: 20px;
  }

  button:nth-child(1) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    height: auto;

    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 0.25rem;
    }
  }

  button:nth-child(4) {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
    height: auto;

    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 0.25rem;
    }
  }

  button:nth-last-child(1) {
    grid-column: 1 / span 3;

    img {
      margin-right: 0.25rem;
    }
  }

}


.widget.detected {
  background: #932F2F;
  color: #FFFFFF;
}

.widget.open {
  background: #EEF7FC;
}

.dashboard-widget-title {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #312F2C;
  justify-content: stretch;

  small {
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}

.dashboard-widget-title-name {
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #504D49;

  margin-bottom: 0.25rem;
}

.dashboard-widget-title-place {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #84827E;
  margin-bottom: 0;
}

.dashboard-widget-info {
  justify-content: flex-end;
  color: #797672;

  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  text-align: right;
}

.dashboard-widget-text {
  color: var(--gray);
  font-size: 10px;
}

.dashboard-widget-info.date {
  .sensor-date {
    color: #84827E;
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

.dashboard-widget-info.temperature {
  color: #D0767B;
  margin-bottom: 0;
}

.dashboard-widget-info.humidity {
  color: #6CA3D4;
  margin-bottom: 0;
}

.dashboard-widget-info.alarm.detected {
  color: #FFFFFF;
}

.dashboard-widget-info.alarm.open {
  color: #4979A5;
}

.dashboard-widget-info.time {
  color: #CEC7BE;
}


.dashboard-widget-info.time.detected {
  color: #00A872;
  font-size: 14px;
}

.device-status.online, .device-status.offline {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.device-status.online {
  color: #797672;
}

.device-status.offline {
  color: #932F2F;
}

.device-status.powerOn {
  color: #00A872;
  display: flex;
  justify-content: space-between;

  svg path {
    fill: #00A872;
  }
}

.device-status.powerOff {
  color: #797672;
  display: flex;
  justify-content: space-between;
}

.device-status.battery {
  display: flex;
  justify-content: space-between;
}

.device-status.battery span {
  color: #797672;
}


.widget.water-green {
  background-color: #EEF9F3;

  span {
    color: #00A872;
  }
}


.temp-title small {
  color: #989691;
}

.temp-title h2 {
  color: #00A872;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.widget-buttons button {
  background-color: #F7F4F1;
  border: none;
  border-radius: 8px !important;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// SensorMotion
// ---------------------------------------------------
.dashboard-widget-footer.sensor {
  p {
    margin-bottom: 0;
    color: #00A872;
  }
}

.widget-right.thSensor {
  small {
   color: #84827E;
  }
  p {
    text-align: left;
    font-size: 20px;
    line-height: 20px;
  }
}

// SensorTh
// ---------------------------------------------------
.dashboard-widget-footer.thSensor {
    display: flex;
    justify-content: flex-end;
}

.widget-right.sensor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: #BCB6AE;
    margin-bottom: 0;
  }

  .recent-detected {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  small {
    color: #84827E;
    font-size: 10px;
  }

  span {
   color: #00A872;
    font-size: 18px;
    font-weight: 700;
  }
}

// Switch
// ---------------------------------------------------
.dashboard-widget-footer.switch {
  display: flex;
  justify-content: flex-start;

  span {
    font-weight: normal;
    font-size: 10px;
    margin-left: 0.5rem;
  }
}

.widget-right.switches {
  gap: 8px !important;
}

.switch-button {
  border: none;
  padding: 0 0.5rem;
  background: #F7F4F1;
  border-radius: 4px !important;
  color: #797672;
  font-weight: 700;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: auto;

  &.active {
    background-color: #FFFBF1;
    color: #F4950B;

    svg path {
      fill: #F4950B;
    }
  }
}
.widget-control {
  span {
    color: #84827E;
    font-size: 10px;
    margin-left: 0.5rem;
  }
}

.dashboard-widget-footer.switches-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    height: 65px;
  }

  button:nth-child(1) {
    grid-column: 1 / 2 span;
  }
}

.dashboard-widget-footer.switches-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    height: 65px;
  }
}

.dashboard-widget-footer.switches-5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    height: 65px;
  }

  button:nth-child(1) {
    grid-column: 1 / 2 span;
  }
}

.dashboard-widget-footer.switches-6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    height: 65px;
  }
}

// SmartDoorLock
// ---------------------------------------------------
.doorlock-widget-buttons {
  display: grid;
  grid-template-columns: repeat(1, 3fr);
  gap: 4px;

  button {
    border: none;
    background: #F7F4F1;
    border-radius: 4px !important;
    height: auto;
    font-size: 10px;
    font-weight: 700;
    color: #797672;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.dashboard-widget-footer.temp-password {
  word-break: keep-all;
  //width: 100%;
  //height: auto;
  display: grid;
  grid-template-columns: auto 20px;
  align-items: flex-end;

  p {
    line-height: 20px;
  }
}


.widget.smartSirenWidget {
  background-color: #fff;

  &.alarm {
    background-color: #932F2F;
  }

  &.alarm h4 {
    color: #fff;
  }

  &.alarm .device-location {
    color: #F2BABA;
  }

  .device-status.alarm, .device-status.normal {
    font-size: 20px;
    justify-content: flex-end;
  }

  .device-status.alarm {
    color: #fff;
  }

  .device-status.normal {
    color: #797672;
  }

  &.power {
    background-color: #EEF9F3;
  }
}

